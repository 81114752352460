<template>
  <section class="m-setting m-scroll">

    <!-- 头部导航 -->
    <m-header title="设置" ></m-header>

    <div class="main">

      <router-link to="/en/setting/user"  class="setting-cell van-hairline--bottom">
        <div class="label">个人信息</div>
        <div class="right">
          <img class="avatar" src="@/assets/fork-imgs/shop03.jpg" alt="">
          <img class="back"   src="@/assets/public/icon-fanhui@2x.png" alt="">
        </div>
      </router-link>

      <router-link to="/en/setting/security"  class="setting-cell van-hairline--bottom">
        <div class="label">账户与安全</div>
        <div class="right">
          <img class="back" src="@/assets/public/icon-fanhui@2x.png" alt="">
        </div>
      </router-link>

      <router-link to="/en/setting/terms"  class="setting-cell van-hairline--bottom">
        <div class="label">条款</div>
        <div class="right">
          <img class="back" src="@/assets/public/icon-fanhui@2x.png" alt="">
        </div>
      </router-link>

      <router-link to="/en/setting/about"  class="setting-cell">
        <div class="label">关于MOOBY</div>
        <div class="right">
          <img class="back" src="@/assets/public/icon-fanhui@2x.png" alt="">
        </div>
      </router-link>

    </div>


    <div class="footer">
      <van-button @click="handleSignOut" round block type="info" native-type="submit" color="linear-gradient(315deg,rgba(240,60,24,1) 0%,rgba(240,100,72,1) 100%)">退出当前账号 </van-button>
    </div>





    

  </section>
</template>

<script>
import MHeader  from '@/components/en/m-header.vue'

export default {
  name:'Index',
  components:{ MHeader },
  data(){
    return {
      src:require('@/assets/fork-imgs/shop01.jpg'),
      nickname:"Ace",
      sex:0,
    }
  },

  

  methods:{

    // 退出当前账号
    handleSignOut(){
      this.$router.push({path:'/en/login'})
    },
   
  },
}
</script>

<style lang="less" scoped>
@import './setting.less';
</style>